















import AppFooter from '~/components/shared/app-footer';

export default {
  name: 'App',

  components: {
    AppFooter
  },

  watch: {
    hasNewVersion(next): void {
      if (next) {
        this.$buefy.snackbar.open({
          message: "Howdy! There's a new version available. Refresh to update.",
          type: 'is-warning',
          position: 'is-top',
          actionText: 'REFRESH NOW',
          indefinite: true,
          onAction: () => {
            // Hard refresh to get the latest version
            window.location.reload(true);
          }
        });
      }
    }
  }, // watch

  computed: {
    hasNewVersion(): boolean {
      return this.$store.state.appRequiresUpdate;
    }
  } // computed
};
