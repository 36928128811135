/**
 * Mail Shield
 * @author Rogerio Taques
 * @copyright 2019, Skulk Enterprises LLC
 *
 * This file handles the APP's initial state.
 *
 * State is the data stored in your application. You components can “receive”
 * this data and when it changes your component will update itself. This is
 * the “single source of truth” in your application.
 */

import { State } from '~/domain/interfaces';

export const state: State = {
  isAppLoading: true,
  isAuthorized: false,
  hasAuthFailed: false,
  error: null,
  apiCallCounter: 0,
  uiLang: null,
  hero: null,
  shields: [],
  filteredShields: [],
  lastShieldCreated: null,
  appRequiresUpdate: false,
  nextRoute: null,
  stripePublicKey: null,
  stripeCheckoutSession: null,
};
