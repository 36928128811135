/**
 * Mail Shield
 * @author Rogerio Taques
 * @copyright 2019, Skulk Enterprises LLC
 *
 * This is used to define all mutations for the APP's store.
 *
 * To update a state you will need to commit a mutation. A mutation does not
 * care about business logic, it’s only purpose is updating the state. A
 * mutation is synchronous (your code has to wait until the mutation is done).
 * Mutations should be the only way used to update your state, to keep your
 * state management predictable.
 */

import { State } from '~/domain/interfaces';

export const mutations = {
  /**
   * Completely reset the state with a new one.
   * @param state
   * @param payload
   */
  resetState(state: State, payload: State) {
    state = Object.assign(state, {
      isAppLoading: false,
      isAuthorized: false,
      uiLang: null,
      hero: null,
      shields: [],
    });
  }, // resetState

  /**
   * Completely reset the state with a new one.
   * @param state
   * @param payload
   * @deprecated never used
   */
  // patchState(state: State, payload: State) {
  //   state = Object.assign(state, payload);
  // }, // patchState

  /**
   * Partially patches the state
   * @param state
   * @param payload
   */
  patchPartialState(state: State, payload: any) {
    state = Object.assign(state, payload);
  }, // patchPartialState

  /**
   * Increase the number of API calls
   * @param state
   */
  increaseAPICall(state: State) {
    state = Object.assign(state, { apiCallCounter: state.apiCallCounter + 1 });
  }, // patchPartial

  /**
   * Decrease the number of API calls
   * @param state
   */
  decreaseAPICall(state: State) {
    let counter = state.apiCallCounter - 1;
    let isFirstLoad = state.isAppLoading;

    if (counter < 0) {
      counter = 0;
    }

    // Keeps 'isAppLoading' true until all initial API calls get complete
    if (isFirstLoad && counter > 0) {
      isFirstLoad = false;
    }

    state = Object.assign(state, {
      apiCallCounter: counter,
      isAppLoading: isFirstLoad,
    });
  }, // patchPartial
};
