/**
 * Mail Shield
 * @author Rogerio Taques
 * @copyright 2019, Skulk Enterprises LLC
 */

// Optional. Makes async/await work in most browsers.
import '@babel/polyfill';

import Buefy from 'buefy';
import Clipboard from 'v-clipboard';
import Vue from 'vue';
import GAuth from 'vue-google-oauth2';
import App from '~/components/app.vue';
import { GOOGLE_AUTH_OPTIONS, IS_PROD } from '~/domain/constants';
import { mixin } from '~/domain/mixins';
import { router } from '~/domain/router';
import { store } from '~/domain/store/vuex';

Vue.mixin(mixin);
Vue.use(GAuth, GOOGLE_AUTH_OPTIONS);
Vue.use(Clipboard);
Vue.use(Buefy);

// A handy logger both in the global scope
// and withing Vue prototype
declare global {
  interface Window {
    $log: any;
  }
}

window.$log = !IS_PROD ? console.log.bind(console) : () => {};
Vue.prototype.$log = window.$log;

new Vue({
  el: '#app',
  router,
  store,
  render: (h: any) => h(App),
});
