




























export default {
  name: 'SpanCopiable',

  props: ['tooltipPosition', 'tooltipType', 'toCopy', 'resetTimeout'],

  data() {
    return {
      copied: false
    };
  },

  methods: {
    onCopyToClipboard(text = '') {
      this.$clipboard(text);
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, this.resetTimeout || 2000);
    } // onCopyToClipboard
  }
};
