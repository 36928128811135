/**
 * Mail Shield
 * @author Rogerio Taques
 * @copyright 2019, Skulk Enterprises LLC
 *
 * This is the (Vuex) store initialization.
 *
 * The store manages the state. It should only be mutated from within,
 * so outside components can not directly modify the state. To mutate
 * the state, components can dispatch an action or commit a mutation.
 */

import Vue from 'vue';
import Vuex from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { state } from './state';

// Tells Vue to use Vuex
Vue.use(Vuex);

// Creates a new store instance
export const store: any = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
