/**
 * Vue Start Kit
 *
 * This is where all the routes are defined.
 *
 * @author Rogerio Taques (hello@abtz.co)
 * @license MIT
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '~/domain/store/vuex';
import { KEY_TOKEN, KEY_LANGUAGE } from '~/domain/constants';
import { verifyToken } from '~/domain/network';

Vue.use(VueRouter);

const routes = [
  // login
  { path: '/', redirect: '/login' },
  { path: '/login', component: () => import('~/components/pages/login.vue') },

  // signup
  { path: '/signup', component: () => import('~/components/pages/signup.vue') },

  // account-delete
  { path: '/account-deleted', component: () => import('~/components/pages/account/deleted.vue') },

  // dashboard
  {
    path: '/dashboard',
    component: () => import('~/components/pages/frame.vue'),
    meta: { protected: true },
    children: [
      {
        path: '/dashboard',
        component: () => import('~/components/pages/dashboard/dashboard.vue')
      }
    ]
  },

  // shield
  {
    path: '/shield',
    component: () => import('~/components/pages/frame.vue'),
    meta: { protected: true },
    children: [
      {
        path: 'add',
        component: () => import('~/components/pages/shields/add.vue')
      },
      {
        path: 'ready',
        component: () => import('~/components/pages/shields/added.vue')
      }
    ]
  },

  // account
  {
    path: '/account',
    component: () => import('~/components/pages/frame.vue'),
    meta: { protected: true },
    children: [
      {
        path: '/account',
        component: () => import('~/components/pages/account/account.vue')
      }
    ]
  },

  // subscription
  {
    path: '/subscription',
    component: () => import('~/components/pages/frame.vue'),
    meta: { protected: true },
    children: [
      {
        path: '/',
        component: () => import('~/components/pages/subscription/overview.vue')
      },
      {
        path: '/subscription/success',
        component: () => import('~/components/pages/subscription/success.vue')
      },
      {
        path: '/subscription/cancel',
        component: () => import('~/components/pages/subscription/overview.vue')
      }
    ]
  }

  // { path: '*', component: Error404 } // catch-all (error page)
];

export const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
});

// Run before every route
router.beforeEach(async (to: any, from: any, next: any) => {
  const lng = localStorage.getItem(KEY_LANGUAGE);

  // Set UI locale when is reloading
  if (lng && !store.state.uiLang) {
    window.$log(`[Router] Locale set to: ${lng.toUpperCase()}`);
    store.commit('patchPartialState', { uiLang: lng });
  }

  if (to.matched.some((record: any) => record.meta.protected)) {
    const localToken: string = localStorage.getItem(KEY_TOKEN) || 'false';
    const isTokenValid = !store.state.isAuthorized && localToken !== 'false' ? await verifyToken(localToken) : false;

    window.$log('[Router] Protected route');

    if (!store.state.isAuthorized && !isTokenValid) {
      window.$log('[Router] User not authenticated');
      await store.dispatch('setNextRoute', to);
      next({ path: '/login' });
    } else if (store.state.nextRoute) {
      const { nextRoute } = store.state;

      window.$log(`[Router] User authorization cleared, requested '${from.query.to}'`);
      await store.dispatch('setNextRoute', null);

      next(nextRoute.path);
    } else {
      window.$log('[Router] User authorization cleared');
      next();
    }
  } else {
    window.$log('[Router] Public route');
    next();
  }
});

export default router;
