/**
 * Mail Shield
 * @author Rogerio Taques
 * @copyright 2019, Skulk Enterprises LLC
 *
 * Everything that needs to be globally available should be placed here.
 */

import { VERSION } from '~/domain/constants';

export const mixin = {
  computed: {
    /**
     * Returns the current year
     */
    currentYear(): string {
      return `${new Date().getFullYear()}`;
    },

    /**
     * Returns the current deployed version number
     */
    currentVersion(): string {
      return `${VERSION}`;
    },
  }, // computed

  methods: {
    /**
     * Return true always the viewport width is smaller than the given width.
     * @param maxWidth
     * @returns boolean
     */
    isViewPortSmallerThan(maxWidth: number = 425): boolean {
      return window.innerWidth <= maxWidth;
    }, // isViewPortSmallerThan

    /**
     * Sets a default value if given value is NULL or UNDEFINED.
     * @param {any} val
     * @param {any} default
     * @returns {any}
     */
    ifn(val: any, def: any): any {
      if (typeof val === 'undefined' || val === null) {
        return def;
      }

      return val;
    }, // ifn
  },
};

export default mixin;
