






















































export default {
  name: 'AppFooter',

  mounted() {
    const head = document.querySelector('html > head');
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5ca2dbdb31a43842';

    head.appendChild(script);
  } // mounted
};
