/**
 * Mail Shield
 * @author Rogerio Taques
 * @copyright 2019, Skulk Enterprises LLC
 */

// Environment
export const ENV: string = process.env.ENV || 'development';
export const VERSION: string = process.env.VERSION || 'devel';
export const API_URL: string = process.env.API_URL || 'http://localhost/mailshld.com/api';
export const IS_PROD: boolean = ENV === 'production';

// Google
export const GOOGLE_API_CLIENT_ID: string = '596559240517-o6gr48sa49j7pbb538i99oq3pjgn7eui.apps.googleusercontent.com';
export const GOOGLE_AUTH_OPTIONS: any = {
  clientId: GOOGLE_API_CLIENT_ID,
  scope: 'email profile openid',
  prompt: 'select_account',
};

// Built-in logger
export const LOGGER_OPTIONS: any = {
  prefix: '',
  dev: true,
  shortname: true,
  levels: ['log', 'warn', 'debug', 'error', 'dir'],
  forceLevels: [],
};

// Local/ Session Storage
export const KEY_PROFILE: string = '_msp';
export const KEY_TOKEN: string = '_mst';
export const KEY_LANGUAGE: string = '_msl';
export const KEY_EXPIRES: string = '_msx';

// App
export const LANGUAGES: Array<string> = ['en', 'pt-BR'];
export const LANGUAGES_NAMES: any = {
  en: 'English',
  'pt-BR': 'Português do Brasil',
};
export const FREE: any = {
  MAX_SHIELDS: 10,
  // MAX_BLACKLISTED: 10
};
export const STRIPE_PLANS: { [key: string]: string } = {
  month: IS_PROD ? 'price_1MUotDBKjJBU5q4OvEwJcsqL' : '', // billed once a month
  year: IS_PROD ? 'price_1MUoptBKjJBU5q4O0yxW7K5V' : '', // billed once a year
};
