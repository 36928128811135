/**
 * Mail Shield
 * @author Rogerio Taques
 * @copyright 2019, Skulk Enterprises LLC
 *
 * This is handling the abstractions for making API requests.
 */

import axios from 'axios';
import { API_URL, VERSION } from '~/domain/constants';
import { store } from '~/domain/store/vuex';
import { Hero } from './interfaces';

/**
 * A PRO error handler for Axios
 * @param error
 * @returns ErrorObject
 */
const errorHandler = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with
    // a status code that falls out of the range of 2xx
    return {
      code: error.response.status,
      message: error.response.data.message,
      severity: 'danger',
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in
    // the browser and an instance of http.ClientRequest in node.js
    const data = error.request.responseText ? JSON.parse(error.request.responseText) : {};

    return {
      code: data.status || 0,
      message: data.message || 'Error parse failed',
      severity: 'danger',
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return {
      code: parseInt(error.message.replace(/[^0-9]/g, ''), 10) || 0,
      message: error.message,
      severity: 'danger',
    };
  }
};

/**
 * Defines an interceptor for any API call
 */
axios.interceptors.response.use(
  (resp: any) => {
    const { data } = resp;

    if (data && data.version && data.version !== VERSION) {
      store.dispatch('setAppRefreshNeeded', true);
    }

    return resp;
  },
  (err: any) => {
    return Promise.reject(errorHandler(err));
  }
);

/**
 * Get user's data from API
 * @return Promise
 */
export const authenticate = (email: string, googleToken: string) => {
  const url = `${API_URL}/auth`;

  window.$log(`[Network] Authenticating user: ${email}`);

  return axios({
    url,
    data: { email, token: googleToken },
    method: 'POST',
  })
    .then((json: any) => {
      window.$log('[Network] User authenticated');
      return json.data.token;
    })
    .catch(() => {
      window.$log('[Network] Failed authenticating the user');
      return 'false';
    });
}; // authenticate

/**
 * Get user's data from API
 * @return Promise
 */
export const register = (email: string, googleToken: string) => {
  const url = `${API_URL}/hero`;

  window.$log(`[Network] Signing up a new hero: ${email}`);

  return axios({
    url,
    data: { email, token: googleToken },
    method: 'POST',
  })
    .then((json: any) => {
      window.$log('[Network] New hero accepted and authenticated');
      return json.data.token;
    })
    .catch((error: any) => {
      window.$log('[Network] Failed to registering the new hero');
      return new Promise((resolve, reject) => reject(errorHandler(error)));
    });
}; // register

/**
 * Call the API to verify if an existing token is still valid
 * @return Promise
 */
export const verifyToken = (token: string) => {
  const url = `${API_URL}/auth/verify`;

  window.$log('[Network] Validating current token');

  return axios({
    url,
    data: { token },
    method: 'POST',
  })
    .then((json: any) => {
      window.$log('[Network] Given token is still valid');
      return json.data.status;
    })
    .catch(() => {
      window.$log('[Network] Given token is not valid');
      return false;
    });
}; // verifyToken

/**
 * Get user's data from API
 * @return Promise
 */
export const getHerosData = (token: string) => {
  const url = `${API_URL}/hero`;

  window.$log("[Network] Retrieving hero's profile");

  return axios({ url, headers: { 'X-Auth': token } })
    .then((json: any) => {
      window.$log('[Network] Profile data retrieved');
      return json.data;
    })
    .catch(() => {
      window.$log("[Network] Failed when retrieving hero's profile data");
      return []; // Prevent error to the user
    });
}; // getHerosData

/**
 * Get user's data from API
 * @return Promise
 */
export const getShieldsFromAPI = (token: string, start: number, offset: number) => {
  const url = `${API_URL}/shields`;

  window.$log("[Network] Retrieving hero's shields");

  return axios({ url, headers: { 'x-auth': token } })
    .then((json: any) => {
      window.$log('[Network] Shields list retrieved');
      return json.data;
    })
    .catch(() => {
      window.$log('[Network] Retrieving shields failed');
      return []; // Prevent error to the user
    });
}; // getShieldsFromAPI

/**
 * Update a given shield status
 * @return Promise
 */
export const updateShieldStatus = (token: string, shieldID: number, resume: boolean) => {
  const url = `${API_URL}/shields/${shieldID}`;

  window.$log('[Network] Updating shield status');

  return axios({
    url,
    headers: { 'x-auth': token },
    data: { is_paused: resume ? 'n' : 'y' },
    method: 'PUT',
  })
    .then((json: any) => {
      window.$log('[Network] Shield status updated');
      return json.data;
    })
    .catch(() => {
      window.$log('[Network] Updating shield status failed');
      return []; // Prevent error to the user
    });
}; // updateShieldStatus

/**
 * Update a given shield label
 * @return Promise
 */
export const updateShieldLabel = (token: string, shieldID: number, label: string) => {
  const url = `${API_URL}/shields/${shieldID}`;

  window.$log('[Network] Updating shield status');

  return axios({
    url,
    headers: { 'x-auth': token },
    data: { label },
    method: 'PUT',
  })
    .then((json: any) => {
      window.$log('[Network] Shield label updated');
      return json.data;
    })
    .catch(() => {
      window.$log('[Network] Updating shield label failed');
      return []; // Prevent error to the user
    });
}; // updateShieldLabel

/**
 * Delete a given shield
 * @return Promise
 */
export const deleteShieldFromAPI = (token: string, shieldID: number) => {
  const url = `${API_URL}/shields/${shieldID}`;

  window.$log('[Network] Deleting shield');

  return axios({
    url,
    headers: { 'x-auth': token },
    method: 'DELETE',
  })
    .then((json: any) => {
      window.$log('[Network] Shield deleted');
      return json.data;
    })
    .catch(() => {
      window.$log('[Network] Delete shield failed');
      return []; // Prevent error to the user
    });
}; // deleteShieldFromAPI

/**
 * Get a new shield
 * @return Promise
 */
export const getNewShieldFromAPI = (token: string) => {
  const url = `${API_URL}/shields`;

  window.$log('[Network] Generating shield');

  return axios({
    url,
    headers: { 'x-auth': token },
    method: 'POST',
  })
    .then((json: any) => {
      window.$log('[Network] Shield generated');
      return json.data;
    })
    .catch((error: any) => {
      window.$log('[Network] Generating shield failed');
      return Promise.reject(error); // Prevent error to the user
    });
}; // getNewShieldFromAPI

/**
 * Completely removes hero's data from Mail Shield
 * @return Promise
 */
export const deleteHeroData = (token: string) => {
  const url = `${API_URL}/hero`;

  window.$log('[Network] Removing account');

  return axios({
    url,
    headers: { 'x-auth': token },
    method: 'DELETE',
  })
    .then((json: any) => {
      window.$log('[Network] Heros account removed');
      return json.data;
    })
    .catch(() => {
      window.$log("[Network] Removing hero's account failed");
      return []; // Prevent error to the user
    });
}; // deleteHeroData

/**
 * Generates a new App Key for Hero
 * @return Promise
 */
export const getAppKey = (token: string) => {
  const url = `${API_URL}/key`;

  window.$log('[Network] Requesting a new App Key');

  return axios({
    url,
    headers: { 'x-auth': token },
    method: 'GET',
  })
    .then((json: any) => {
      window.$log('[Network] App key retrieved');
      return json.data;
    })
    .catch(() => {
      window.$log('[Network] Retrieving new App Key has failed');
      return []; // Prevent error to the user
    });
}; // getAppKey

/**
 * Update a given hero's account details.
 * @return Promise
 */
export const updateHeroAccount = (token: string, hero: Hero) => {
  const url = `${API_URL}/hero`;

  window.$log("[Network] Updating hero's account");

  return axios({
    url,
    headers: { 'x-auth': token },
    data: hero,
    method: 'PUT',
  })
    .then((json: any) => {
      window.$log("[Network] Hero's account updated");
      return json.data;
    })
    .catch(() => {
      window.$log("[Network] Updating hero's account failed");
      return []; // Prevent error to the user
    });
}; // updateHeroAccount

/**
 * Get the Stripe checkout session (which is only valid for 24 hours).
 * @return Promise
 */
export const getStripeCheckoutSessionData = (token: string, planID: string) => {
  const url = `${API_URL}/stripe/session/${planID}`;

  window.$log('[Network] Getting Stripe checkout session ...');

  return axios({
    url,
    headers: { 'x-auth': token },
    method: 'GET',
  })
    .then((json: any) => {
      window.$log('[Network] Stripe checkout session gathered');
      return json.data;
    })
    .catch(() => {
      window.$log('[Network] Failed when gathering Stripe checkout session');
      return []; // Prevent error to the user
    });
}; // getStripeCheckoutSessionData

/**
 * Get the Stripe portal session.
 * @return Promise
 */
export const getStripePortalSessionData = (token: string) => {
  const url = `${API_URL}/stripe/portal`;

  window.$log('[Network] Getting Stripe portal session ...');

  return axios({
    url,
    headers: { 'x-auth': token },
    method: 'GET',
  })
    .then((json: any) => {
      window.$log('[Network] Stripe portal session gathered');
      return json.data;
    })
    .catch(() => {
      window.$log('[Network] Failed when gathering Stripe portal session');
      return []; // Prevent error to the user
    });
}; // getStripePortalSessionData
