















export default {
  name: 'GoogleOauth',

  props: {
    isAuthorized: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    isInit: false,
    isAuthenticating: false
  }),

  methods: {
    /**
     * Abstraction for the button click handling the proper action depending on the
     * authentication status.
     */
    handleClick() {
      if (this.isAuthorized) {
        return this.handleClickSignOut();
      }

      return this.handleClickSignIn();
    },

    /**
     * Handle the sign in click
     * @returns void
     * @emits 'signedin' || 'failed'
     */
    handleClickSignIn() {
      this.isAuthenticating = true;

      this.$gAuth
        .signIn()
        .then((googleUser: any) => {
          // On success do something, refer to:
          // https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetid

          const isAuthorizedByGoogle = this.$gAuth.isAuthorized;

          if (isAuthorizedByGoogle) {
            const token = googleUser.getAuthResponse().id_token;
            const profile = googleUser.getBasicProfile();
            const email = profile.getEmail();

            this.$log(`[GoogleOauth] User authorized with: ${email}`);
            this.$emit('signedin', { email, token });
          } else {
            this.$log('[GoogleOauth] User authorization failed');
            this.$emit('failed');
          }

          this.isAuthenticating = false;
        })
        .catch((error: any) => {
          this.$log('[GoogleOauth] User authorization failed', error);
          this.$emit('failed');
          this.isAuthenticating = false;
        });
    },

    /**
     * Handles the sign out call.
     * @returns void
     * @emits 'signedout' || 'failed'
     */
    handleClickSignOut() {
      this.isAuthenticating = true;

      this.$gAuth
        .signOut()
        .then(() => {
          const isAuthorized = this.$gAuth.isAuthorized;

          if (!isAuthorized) {
            this.$log('[GoogleOauth] User signed out');
            this.$emit('signedout');
          }
        })
        .catch((error: any) => {
          this.$log('[GoogleOauth] User signout failed', error);
          this.$emit('failed');
        });
    }
  },

  /**
   * When the component is mounted
   * @returns void
   * @emits 'authorized' || nothing
   */
  mounted() {
    const that = this;

    const checkGoogleOauthLoad = setInterval(() => {
      that.isInit = that.$gAuth.isInit;

      if (that.isInit) {
        this.$log('[GoogleOauth] Initialized');
        clearInterval(checkGoogleOauthLoad);
      }
    }, 150);
  }
};
