/**
 * Mail Shield
 * @author Rogerio Taques
 * @copyright 2019, Skulk Enterprises LLC
 *
 * This is used to define all mutations for the APP's store.
 *
 * Getters compute properties based on the store state. You can use these
 * in your actions or directly in your component. This is useful if several
 * components need to calculate the same thing based on the store data, you
 * can do it in one place instead of having to do it separately for each
 * component.
 */

import { Error, Hero, Shield, State } from '~/domain/interfaces';

export const getters = {
  /**
   * Indicates whether the APP is in its first load.
   * @param state
   * @return boolean
   */
  isAppLoaded(state: State): boolean {
    return !state.isAppLoading;
  },

  /**
   * Gets logged errors.
   * @param state
   * @return Error
   */
  error(state: State): Error | null {
    return state.error;
  },

  /**
   * Gets logged user data.
   * @param state
   * @return Hero
   */
  hero(state: State): Hero | null {
    return state.hero;
  },

  /**
   * Gets all shields from a user.
   * @param state
   * @return Array<Shield>
   */
  shields(state: State): Array<Shield> {
    return state.shields;
  },

  /**
   * Indicates whether the APP is loading data from the API.
   * @param state
   * @return boolean
   */
  isCallingAPI(state: State): boolean {
    return state.apiCallCounter > 0;
  },

  /**
   * Indicates whether the current user is (or is not) authorized .
   * @param state
   * @return boolean
   */
  isAuthorized(state: State): boolean {
    return state.isAuthorized;
  },

  /**
   * Get current user language or fallsback to the default
   * @param state
   * @return string
   */
  currentLanguage(state: State): string {
    return state.uiLang ? state.uiLang.substr(0, 2) : 'en';
  },
};
