/**
 * Mail Shield
 * @author Rogerio Taques
 * @copyright 2019, Skulk Enterprises LLC
 *
 * This is used to define all actions from the APP's store.
 *
 * An action contains business logic and it does not care about updating
 * the state directly. The reason is that actions are asynchronous (your
 * code can continue to run even if the action is not finished yet), this
 * is useful if you need to wait to receive data from an API for example.
 * An action will dispatch a mutation, which will directly update the
 * state.
 */

import { Hero, Shield } from '~/domain/interfaces';

import { KEY_EXPIRES, KEY_LANGUAGE, KEY_PROFILE, KEY_TOKEN } from '~/domain/constants';

import {
  authenticate,
  deleteHeroData,
  deleteShieldFromAPI,
  getAppKey,
  getHerosData,
  getNewShieldFromAPI,
  getShieldsFromAPI,
  getStripeCheckoutSessionData,
  getStripePortalSessionData,
  register,
  updateHeroAccount,
  updateShieldLabel,
  updateShieldStatus,
} from '~/domain/network';

export const actions = {
  /**
   * Patch flag for version update.
   * @param context
   * @param error
   */
  setNextRoute(context: any, nextRoute: any) {
    context.commit('patchPartialState', {
      nextRoute,
    });
  }, // setNextRoute

  /**
   * Patch flag for version update.
   * @param context
   * @param error
   */
  setAppRefreshNeeded(context: any, appRequiresUpdate: boolean = true) {
    context.commit('patchPartialState', {
      appRequiresUpdate,
    });
  }, // setAppRefreshNeeded

  /**
   * Updates the UI language in the state
   * @param context
   * @param error
   */
  patchError(context: any, error: Error) {
    context.commit('patchPartialState', {
      error,
    });
  }, // patchError

  /**
   * Reset all errors vars to its initial state
   * @param context
   */
  resetError(context: any) {
    context.commit('patchPartialState', {
      hasAuthFailed: false,
      error: null,
    });
  }, // resetError

  /**
   * Updates the UI language in the state
   * @param context
   * @param lang
   */
  patchLanguage(context: any, lang: string) {
    context.commit('patchPartialState', {
      uiLang: lang,
    });

    localStorage.setItem(KEY_LANGUAGE, lang);
  }, // patchLanguage

  /**
   * Updates the UI language in the state
   * @param context
   * @param shields
   */
  patchFilteredShields(context: any, shields: Array<Shield>) {
    context.commit('patchPartialState', {
      filteredShields: shields,
    });
  }, // patchFilteredShields

  /**
   * Clear user profile and authentication data from both state and localStorage.
   * @param context
   */
  clearSession(context: any) {
    localStorage.removeItem(KEY_PROFILE);
    localStorage.removeItem(KEY_TOKEN);
    localStorage.removeItem(KEY_EXPIRES);
    context.commit('resetState');
  }, // clearSession

  /**
   * Authenticate signed in Google User against Mail Shield API.
   * @param context
   * @param payload
   */
  authenticateHero(context: any, payload: { email: string; token: string }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const token: string = await authenticate(payload.email, payload.token);

      context.commit('increaseAPICall');

      if (token !== 'false' && typeof token !== 'undefined') {
        localStorage.setItem(KEY_TOKEN, token);

        context.commit('patchPartialState', {
          isAuthorized: true,
          hasAuthFailed: false,
        });

        resolve();
      } else {
        localStorage.removeItem(KEY_TOKEN);

        context.commit('patchPartialState', {
          isAuthorized: false,
          hasAuthFailed: true,
        });

        reject(new Error('Authentication has failed!'));
      }

      context.commit('decreaseAPICall');
    });
  }, // authenticateHero

  /**
   * Authenticate signed in Google User against Mail Shield API.
   * @param context
   * @param payload
   */
  registerHero(context: any, payload: { email: string; token: string }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let token: string;

      context.commit('increaseAPICall');

      try {
        token = await register(payload.email, payload.token);

        localStorage.setItem(KEY_TOKEN, token);

        context.commit('patchPartialState', {
          isAuthorized: true,
          hasAuthFailed: false,
          error: null,
        });

        resolve();
      } catch (error) {
        localStorage.removeItem(KEY_TOKEN);

        context.commit('patchPartialState', {
          isAuthorized: false,
          hasAuthFailed: true,
        });

        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // registerHero

  /**
   * Get all data from Hero's profile
   * @param context
   */
  getHeroProfile(context: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const currentToken: string = localStorage.getItem(KEY_TOKEN) || 'undefined';

      if (currentToken === 'undefined') {
        reject(new Error('Token is undefined! '));
        return;
      }

      context.commit('increaseAPICall');

      let hero, token;

      try {
        ({ hero, token } = await getHerosData(currentToken));

        if (hero) {
          // Parse received data
          hero.lang = hero.lang.substr(0, 2);

          context.commit('patchPartialState', {
            hero,
          });
        }

        // Updates local cached token
        if (token) {
          localStorage.setItem(KEY_TOKEN, token);
        }

        resolve();
      } catch (error) {
        // this.clearSession(context);
        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // fetchHeroData

  /**
   * Get all existing shields from an user
   * @param context
   * @param payload
   */
  getShields(context: any, payload: { start?: number; offset?: number } = {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let shields: Shield[] | [] = [];
      let token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';

      if (token === 'undefined') {
        reject(new Error('Token is undefined!'));
        return;
      }

      context.commit('increaseAPICall');

      try {
        ({ shields, token } = await getShieldsFromAPI(token, payload.start || 0, payload.offset || 1000));

        if (token) {
          localStorage.setItem(KEY_TOKEN, token);
        }

        if (shields) {
          context.commit('patchPartialState', {
            shields,
            filteredShields: shields,
          });
        }

        resolve();
      } catch (error) {
        // this.clearSession(context);
        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // getShields

  /**
   * Pause or resume a shield
   * @param context
   * @param payload
   */
  resumePauseShield(context: any, payload: { resume: boolean; shieldID: number }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let shields: Array<Shield> | [] = [];
      let token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';

      if (token === 'undefined') {
        reject(new Error('Token is undefined!'));
        return;
      }

      context.commit('increaseAPICall');

      try {
        // Update shield status
        ({ token } = await updateShieldStatus(token, payload.shieldID, payload.resume));

        // Retrieve updated list of shields
        ({ shields, token } = await getShieldsFromAPI(token, 0, 1000));

        if (token) {
          localStorage.setItem(KEY_TOKEN, token);
        }

        if (shields) {
          context.commit('patchPartialState', {
            shields,
            filteredShields: shields,
          });
        }

        resolve();
      } catch (error) {
        // this.clearSession(context);
        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // resumePauseShield

  /**
   * Delete a shield
   * @param context
   * @param shieldID
   */
  deleteShield(context: any, shieldID: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let shields: Array<Shield> | [] = [];
      let token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';

      if (token === 'undefined') {
        reject(new Error('Token is undefined!'));
        return;
      }

      context.commit('increaseAPICall');

      try {
        // Retrieve updated list of shields
        ({ token } = await deleteShieldFromAPI(token, shieldID));

        // Retrieve updated list of shields
        ({ shields, token } = await getShieldsFromAPI(token, 0, 1000));

        if (shields) {
          context.commit('patchPartialState', {
            shields,
            filteredShields: shields,
          });
        }

        localStorage.setItem(KEY_TOKEN, token);

        resolve();
      } catch (error) {
        // this.clearSession(context);
        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // deleteShield

  /**
   * Update a shield label
   * @param context
   * @param payload
   */
  updateShieldLabel(context: any, payload: { id: number; label: string }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let shields: Shield[] | [] = [];
      let token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';

      if (token === 'undefined') {
        reject(new Error('Token is undefined!'));
        return;
      }

      context.commit('increaseAPICall');

      try {
        // Retrieve updated list of shields
        ({ token } = await updateShieldLabel(token, payload.id, payload.label));

        // Retrieve updated list of shields
        ({ shields, token } = await getShieldsFromAPI(token, 0, 1000));

        if (token) {
          localStorage.setItem(KEY_TOKEN, token);
        }

        if (shields) {
          context.commit('patchPartialState', {
            shields,
            filteredShields: shields,
          });
        }

        resolve();
      } catch (error) {
        // this.clearSession(context);
        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // updateShieldLabel

  /**
   * Get a new shield
   * @param context
   */
  getNewShield(context: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let shieldID: number = 0;
      let shield: string = '';
      let shields: Shield[] | [] = [];
      let token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';

      if (token === 'undefined') {
        reject(new Error('Token is undefined.'));
        return;
      }

      context.commit('increaseAPICall');
      context.commit('patchPartialState', { lastShieldCreated: null });

      try {
        // Retrieve updated list of shields
        const resp = await getNewShieldFromAPI(token);

        ({ token, shields, shield } = resp);

        shieldID = resp.shield_id;

        if (token) {
          localStorage.setItem(KEY_TOKEN, token);
        }

        if (shields) {
          context.commit('patchPartialState', {
            shields,
          });
        }

        if (shield && shieldID) {
          context.commit('patchPartialState', {
            lastShieldCreated: `${shieldID}$${shield}`,
          });
        }

        resolve();
      } catch (error) {
        context.commit('patchPartialState', { error });
        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // getNewShield

  /**
   * Get a new shield
   * @param context
   */
  deleteHeroAccount(context: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';

      if (token === 'undefined') {
        reject(new Error('Token is undefined!'));
        return;
      }

      context.commit('increaseAPICall');

      try {
        // Wipe hero's account
        await deleteHeroData(token);

        context.commit('patchPartialState', {
          hero: null,
          shields: [],
          isAuthorized: false,
        });

        localStorage.removeItem(KEY_TOKEN);
        this.clearSession(context);

        resolve();
      } catch (error) {
        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // deleteHeroAccount

  /**
   * Get a new app key
   * @param context
   */
  getNewAppKey(context: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';

      if (token === 'undefined') {
        reject(new Error('Token is invalid!'));
        return;
      }

      context.commit('increaseAPICall');

      try {
        // Get hero's data updated with new app key
        const { hero } = await getAppKey(token);

        context.commit('patchPartialState', {
          hero,
        });

        resolve();
      } catch (error) {
        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // getNewAppKey

  /**
   * Updates the user account
   * @param context
   * @param hero
   */
  patchHeroAccount(context: any, hero: Hero): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';

      if (token === 'undefined') {
        reject(new Error('Token is invalid!'));
        return;
      }

      context.commit('increaseAPICall');

      try {
        // Update hero's account
        await updateHeroAccount(token, hero);

        context.commit('patchPartialState', {
          hero,
        });

        resolve();
      } catch (error) {
        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // patchHeroAccount

  /**
   * Gets the Stripe checkout session (which is only valid for 24 hours).
   * @param context
   * @param payload
   */
  getStripeCheckoutSession(context: any, planID: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';
      let stripe_session: string;
      let stripe_public_key: string;

      if (token === 'undefined') {
        reject(new Error('Token is undefined!'));
        return;
      }

      context.commit('increaseAPICall');

      try {
        ({ stripe_session, stripe_public_key } = await getStripeCheckoutSessionData(token, planID));

        context.commit('patchPartialState', {
          stripePublicKey: stripe_public_key,
          stripeCheckoutSession: stripe_session,
        });

        resolve();
      } catch (error) {
        context.commit('patchPartialState', {
          stripePublicKey: null,
          stripeCheckoutSession: null,
        });

        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // getStripeCheckoutSession

  /**
   * Gets the Stripe portal session.
   * @param context
   * @param payload
   */
  getStripePortalSession(context: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const token: string = localStorage.getItem(KEY_TOKEN) || 'undefined';
      let stripe_session: string;
      let stripe_public_key: string;

      if (token === 'undefined') {
        reject(new Error('Token is undefined!'));
        return;
      }

      context.commit('increaseAPICall');

      try {
        ({ stripe_session, stripe_public_key } = await getStripePortalSessionData(token));

        context.commit('patchPartialState', {
          stripePublicKey: stripe_public_key,
          stripePortalSession: stripe_session,
        });

        resolve();
      } catch (error) {
        context.commit('patchPartialState', {
          stripePublicKey: null,
          stripePortalSession: null,
        });

        reject(error);
      }

      context.commit('decreaseAPICall');
    });
  }, // getStripePortalSession
};
